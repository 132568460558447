import { useEffect, useState } from "react";
import axios from 'axios';
import { olUtils } from '@avinet/react-openlayers';
import { Feature } from 'ol';
import { Point } from 'ol/geom';

const source = 'Szczytno';

const processRecord = (record, source) => {
  const feature = olUtils.createFeatureFromWkt(record.geom_wkt);
  return  {
    olfeature: feature,
    sources: source,
    id: record.id,
    komentarz: record.komentarze_uwagi,
    title: record.tytul,
    content: record.opis,
    foto: record.foto,
    link: record.link,
    wartosc: record.wartosc,
    grafika: record.grafika,
    ...record,
  };
}

let features = [];

const useFeatures = () => {
  
  const [loading, setLoading] = useState(false);
  const [articles, setArticles] = useState([]);
    
  useEffect(() => {
    axios.post("https://mapa.miastaidrzewa.gridw.pl/WebServices/client/DataView.asmx/ReadAny", {
      headers: {
        'Content-Type' : 'application/json; charser=UTF-8'
      },
      request: {
        "theme_uuid": "49c0984a-6ced-43fc-a34c-ea636252678f",
        "columns": ["id", "komentarze_uwagi", "tytul", "foto", "opis", "link", "wartosc", "grafika"],
        "excludeGeometry":false,
        "sortByGeomColumn":"geom",
        "sortByGeomGeometry":"POINT(2315000 6870000)",
        "start":0,
        "limit":100,
        "filter":{
        "filterColumns":[],
        "defaultFilter":[],
        "sortColumns":[],
        "distinctColumns":[]},
        "extraParams":[],
        "srid": "900913"
      }
    })
    .then(res => {
      const data = res.data.d.records;
      const group = [];

      data.forEach(element => {
        group.push(processRecord(element, source));  
        const newFeature = processRecord(element, source);
        features.push(new Feature({
          geometry: new Point(newFeature.olfeature.values_.geometry.flatCoordinates),
          title: newFeature.tytul,
          content: newFeature.opis,
          foto: newFeature.foto,
          link: newFeature.link,
          komentarz: newFeature.komentarze_uwagi,
          wartosc: newFeature.wartosc,
          grafika: newFeature.grafika
        }))
      })
      setArticles(articles.concat(group))
      setLoading(true);
    })

  }, []);

  return {features, loading};
}

export default useFeatures;
